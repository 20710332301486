import { CommonModule, NgOptimizedImage } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { CxMenuModule, CxSecureImageModule } from '@bbraun/cortex/carousel';
import { CxDialogModule } from '@bbraun/cortex/dialog';
import { CxHeaderModule } from '@bbraun/cortex/header';
import { CxNavigationDrawerModule } from '@bbraun/cortex/navigation-drawer';
import { CxProfileDropdownComponent } from '@bbraun/cortex/profile-dropdown';
import { HttpModule, HttpService } from '@bbraun/helpex/src/lib/http';
import { TranslocoModule } from '@ngneat/transloco';

import { AvatarComponent } from './components/avatar/avatar.component';
import { ImageCropperComponent } from './components/avatar/image-cropper/image-cropper.component';
import { HeaderComponent } from './components/header/header.component';
import { UserDetailsPanelComponent } from './components/user-details-panel/user-details-panel.component';

const modules = [
  TranslocoModule,
  CxHeaderModule,
  CxDialogModule,
  CxProfileDropdownComponent,
  CxNavigationDrawerModule,
  CxSecureImageModule,
  NgOptimizedImage,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
  MatButtonModule,
  MatDividerModule,
  HttpModule
];

const components = [ HeaderComponent, UserDetailsPanelComponent, AvatarComponent, ImageCropperComponent ];

@NgModule({ declarations: [ ...components ],
  exports: [ ...modules, ...components ],
  imports: [ ...modules, CommonModule, RouterModule, CxMenuModule ],
  providers: [ HttpService, provideHttpClient(withInterceptorsFromDi()) ] })
export class SharedModule {}
